import React, { HTMLAttributes } from "react";
import Spinner from "react-bootstrap/Spinner";

import "./styles.scss";

interface FullPageSpinnerProps extends HTMLAttributes<HTMLDivElement> {
  negativeTop?: boolean;
}

function FullPageSpinner({
  className,
  negativeTop,
}: FullPageSpinnerProps): JSX.Element {
  return (
    <div
      className={`full-page-spinner${negativeTop ? " negative-top" : ""}${
        className ? ` ${className}` : ""
      }`}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default FullPageSpinner;
