import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  ReactElement,
} from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "utils/axiosUtil";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUDIENCE,
} = process.env;

type AuthContextType = {
  isAuthenticated: boolean;
  logout: () => void;
  user: any;
};

const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  logout: () => undefined,
  user: null,
});

interface AuthProviderProps {
  children: ReactElement;
}

function AuthProvider(props: AuthProviderProps): JSX.Element {
  const [token, setToken] = useState<string | null>(null);
  const location = useLocation();

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const handleLogin = useCallback(async (): Promise<void> => {
    try {
      await loginWithRedirect({
        appState: { returnTo: location.pathname + location.search },
      });
    } catch (e) {
      console.log(e);
    }
  }, [location, loginWithRedirect]);

  const handleLogout = useCallback((): void => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const fetchToken = useCallback(async (): Promise<void> => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE,
      });

      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      setToken(accessToken);
    } catch (e) {
      console.log(e.message);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isLoading && !user) {
      handleLogin();
    }
  }, [handleLogin, isLoading, user]);

  useEffect(() => {
    if (isAuthenticated && !token) {
      fetchToken();
    }
  }, [fetchToken, isAuthenticated, token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: Boolean(isAuthenticated && token),
        logout: handleLogout,
        user,
      }}
      {...props}
    />
  );
}

function AuthProviderWithHistory({ children }: AuthProviderProps): JSX.Element {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState): void => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN as string}
      clientId={REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={window.location.origin}
      audience={REACT_APP_AUDIENCE as string}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthProvider>{children}</AuthProvider>
    </Auth0Provider>
  );
}

function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

export { AuthProviderWithHistory, useAuth };
