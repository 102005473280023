import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "@canopyapps/canopy-ui";
import { AuthProviderWithHistory } from "../src/contexts/AuthContext";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

import "@canopyapps/canopy-ui/dist/index.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <Router>
    <AuthProviderWithHistory>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </QueryClientProvider>
    </AuthProviderWithHistory>
    <ToastContainer />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
