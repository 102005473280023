import React from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAuth } from "../../contexts/AuthContext";
import "./styles.scss";

type CanopyNavBarProps = {
  image: string;
  user?: {
    email: string;
  };
};

const CanopyNavBar: React.FunctionComponent<CanopyNavBarProps> = ({
  image,
  user,
}) => {
  const { logout } = useAuth();
  const history = useHistory();

  return (
    <Navbar bg="primary" className="shadow">
      <Navbar.Brand className="mr-auto">
        <Link to="/">
          <img src={image} alt="Canopy Logo" height="30" width="110" />
        </Link>
      </Navbar.Brand>
      <NavDropdown title={user?.email || ""} id="basic-nav-dropdown">
        <NavDropdown.Item onClick={(): void => history.push("")}>
          My Exams
        </NavDropdown.Item>
        <NavDropdown.Item href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta">
          Support
        </NavDropdown.Item>
        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  );
};

export default CanopyNavBar;
