import React, { useState, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CanopyNavBar from "./components/CanopyNavBar";
import FullPageSpinner from "./components/FullPageSpinner";
import {
  RegistrationContext,
  Registration,
  defaultRegistrationContext,
} from "./contexts/RegistrationContext";
import { useAuth } from "./contexts/AuthContext";

const ExamPreAssessmentSuccess = lazy(
  () => import("./pages/exam/pre_assessment/success")
);
const ReportCard = lazy(() => import("./pages/report_card/ReportCard"));
const Certificate = lazy(() => import("./pages/certificate"));
const PreAssessment = lazy(() => import("./pages/preAssessment"));
const PreAssessmentScore = lazy(() => import("./pages/preAssessmentScore"));
const TestScore = lazy(() => import("./pages/testScore"));
const Credential = lazy(() => import("./pages/credential"));
const Products = lazy(() => import("./pages/products"));
const Checkout = lazy(() => import("./pages/checkout"));
const CheckoutConfirmation = lazy(() => import("./pages/checkoutConfirmation"));
const NotFound = lazy(() => import("./pages/notFound"));

const App = (): JSX.Element => {
  const [registration, setRegistration] = useState(defaultRegistrationContext);
  const { isAuthenticated, user } = useAuth();

  const updateRegistration = (data: Registration): void => {
    setRegistration(data);
  };

  return isAuthenticated ? (
    <>
      <CanopyNavBar image={require("./_assets/canopy.png")} user={user} />
      <RegistrationContext.Provider
        value={{ registration, updateRegistration }}
      >
        <Suspense fallback={<FullPageSpinner negativeTop />}>
          <Switch>
            <Route exact path="/" component={Credential} />
            <Route
              exact
              path="/survey/success"
              component={ExamPreAssessmentSuccess}
            />
            <Route exact path="/assessment/:testId" component={PreAssessment} />
            <Route
              exact
              path="/assessment-score/:testId"
              component={PreAssessmentScore}
            />
            <Route exact path="/test-score/:testId" component={TestScore} />
            <Route exact path="/reportcard" component={ReportCard} />
            <Route exact path="/certificate" component={Certificate} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/checkout/:productId" component={Checkout} />
            <Route
              exact
              path="/checkout-confirmation"
              component={CheckoutConfirmation}
            />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </RegistrationContext.Provider>
    </>
  ) : (
    <FullPageSpinner />
  );
};

export default App;
