import React from "react";

type RegistrationContextType = {
  registration: Registration;
  updateRegistration: (data: Registration) => void;
};

export type Registration = {
  name: string;
  description: string;
  startDate: string;
  expirationDate: string;
  testTime: string;
  testLength: number;
};

export const defaultRegistrationContext = {
  name: "",
  description: "",
  startDate: "",
  expirationDate: "",
  testTime: "",
  testLength: 0,
};

/** Context for Exam Registration Success and Confirmation Modal */
export const RegistrationContext = React.createContext<RegistrationContextType>(
  {
    registration: defaultRegistrationContext,
    updateRegistration: () => ({}),
  }
);
